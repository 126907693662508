import { UserInfo } from '@core/models/interfaces/auth';
import { createAction, props } from '@ngrx/store';

export const register = createAction('[Auth Page] Register', props<{ email: string; password: string }>());
export const registerSuccess = createAction('[Auth Page] Register');
export const registerFailed = createAction('[Auth Page] Register', props<{ error: string }>());

export const login = createAction('[Auth Page] Login', props<{ email: string; password: string }>());
export const loginSuccess = createAction(
  '[Auth Page] Login Success',
  props<{ accessToken: string; userInfo?: UserInfo }>()
);
export const loginFailed = createAction('[Auth Page] Login Failed', props<{ error: string }>());

export const setAuthState = createAction(
  '[Auth Page] Set Auth State',
  props<{ accessToken: string; userInfo?: UserInfo; isLoggedIn: boolean }>()
);

export const logout = createAction('[Auth Page] Logout');

export const updateUserInfoField = createAction(
  '[Auth Page] Update UserInfo Field',
  props<{ field: keyof UserInfo; value: string | boolean }>()
);
